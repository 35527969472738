import react from '../assets/svg/achievements/react.svg'
import community from '../assets/svg/achievements/community.svg'
import team from '../assets/svg/achievements/Team.png'

export const achievementData = {
    bio : "With a relentless drive for growth and improvement, I strive to create solutions that positively impact society and the lives of others. This mindset has fueled accomplishments throughout my journey, both quantifiable and intangible.\n\nTo demonstrate the scope of my skills and the tangible results I've achieved, I invite you to explore my past projects, which serve as a testament to my dedication and innovative approach.",
        achievements : [
        {
            id : 1,
            title : 'Google Cloud certification',
            details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 2,
            title : 'ReactJS and Redux Saga certification',
            details : 'Validate technical skills in ReactJS expertise.',
            date : 'Aug 20, 2019',
            field : 'Tech',
            image : react
        },
        {
            id : 3,
            title : 'Mobilized a 5000 members community of youth to raise awareness among important social issues and tech adaptability including NFT\'s',
            details : 'Professional leadership qualities were tested, and an experience of starting something from scratch and building a viable model',
            date : 'Aug 20, 2019',
            field : 'Leadership',
            image : community
        },
        {
            id : 4,
            title : 'OpenSource contributions in some notable opensource projects like Material-UI and codevault',
            details : 'Expertise towards contributing to major open-source libraries',
            date : 'Aug 20, 2019',
            field : 'Tech',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 5,
            title : 'Trained and led a team of 5 juniors, reviewed their codebases to enhance their skillset',
            details : 'Leadership and mentorship qualities validated',
            date : 'Aug 20, 2019',
            field : 'Tech',
            image : team
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/