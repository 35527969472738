export const educationData = [
    {
        id: 1,
        institution: 'FAST NUCES',
        course: 'Bachelor of Computer Science',
        startYear: '2016',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'Beaconhouse School System',
        course: 'Cambridge GCE A-Levels, Pre-Engineering',
        startYear: '2014',
        endYear: '2016'
    },
]