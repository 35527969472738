export const socialsData = {
    github: 'https://github.com/Hameezr',
    // facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/hameezr/',
    instagram: 'https://www.instagram.com/hameezr/',
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://medium.com/@hameezrizwan',
    medium: 'https://medium.com/@hameezrizwan',
    stackOverflow: 'https://stackoverflow.com/users/14325486/hameez-rizwan',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}